import { registerApplication, start } from 'single-spa';
import { constructApplications, constructRoutes, constructLayoutEngine } from 'single-spa-layout';
import { store } from '@creditas/global-store';
import { queryClient } from './services/queryClient';
import { startMonitoring } from './services/monitoring';
import microfrontendLayout from './microfrontend-layout.html';

const data = {
  loaders: {},
  props: {
    queryClient,
    globalState: store,
  },
};

const routes = constructRoutes(microfrontendLayout, data);
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});
const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach(registerApplication);
layoutEngine.activate();

if (process.env.USE_MSW_MOCK === 'true') {
  import('./mocks/browser').then(({ worker }) => {
    worker.start({
      onUnhandledRequest: 'bypass',
    });
  });
}

startMonitoring();

start();
